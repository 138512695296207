//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

$primary: #FFC905;
$warning: #FFC905;
$success: #28c76f;
$black-brown: #212121;
$light-gray: #f4f5f6;
$light-black: #23282c;
$light-blue: #20a8d8;
$dark-blue: #167495;


// $horizontal-menu-height: 4.45rem !default;


@font-face {
  font-family: "Muli-Regular";

  src: url("../../fonts/muli/Muli-Regular.ttf")
}

$theme-colors: (
  'primary':$primary,
  'warning':$warning,
  'success': $success,
  'gray-active': #f4f5f6,
  'danger-dark': #ED474A,
  'dark-gray': #e4e5e6,
  'black-brown': $black-brown,
  'light-gray': $light-gray
);


$font-family-monospace: "Muli-Regular";
$font-family-sans-serif: "Muli-Regular";